// Autogenerated locales - do not modify
/* tslint:disable quotemark variable-name */
export const CANCEL = "Zru\u0161";
export const DELETE_COMMENT = "Zma\u017e";
export const FUPLOAD_DISABLE_PREPROCESSING = "Nahraj fotku v max. kvalite (trv\u00e1 to dlh\u0161ie)";
export const HOLD_DOWN_KEY = "Pri v\u00fdbere fotiek pridr\u017e kl\u00e1vesu";
export const NOT_LOGGED = "neprihl\u00e1sen\u00fd";
export const REPLY_LINK = "Odpovedz";
export const SAVE_DESCRIPTION = "Ulo\u017e popis";
export const SENDING_PHOTOGRAPH = "Posielam fotografiu...";
export const SENDING_PHOTOGRAPHS = "Posielam fotografie...";
export const TIP = "Tip";
export const TO_SELECT_MULTIPLE_IMAGES = ", aby si vybrala viac fotiek naraz";
